const SET_PORTFOLIO_DATA = "SET_PORTFOLIO_DATA";
const SET_SELECTED_PORTFOLIO_ID = "SET_SELECTED_PORTFOLIO_ID";
const SET_LEETCODE_DATA = "SET_LEETCODE_DATA";
const SET_SELECTED_LEETCODE_ID = "SET_SELECTED_LEETCODE_ID";
const SET_LEETCODE_TABLE_COLUMNS = " SET_LEETCODE_TABLE_COLUMNS";
const SET_LEETCODE_TABLE_PAGENATION = "SET_LEETCODE_TABLE_PAGENATION";
const SET_LEETCODE_TABLE_SORTER = "SET_LEETCODE_TABLE_SORTER";
const SET_LEETCODE_TABLE_FILTER = "SET_LEETCODE_TABLE_FILTER";
const SET_LEETCODE_TABLE_FILTER_TYPE = "SET_LEETCODE_TABLE_FILTER_TYPE";
const SET_APPLICATION_DATA = "SET_APPLICATION_DATA";
const SET_SELECTED_APPLICATION_ID = "SET_SELECTED_APPLICATION_ID";
const SET_APPLICATION_TABLE_COLUMNS = "SET_APPLICATION_TABLE_COLUMNS";
const SET_APPLICATION_TABLE_PAGENATION = "SET_APPLICATION_TABLE_PAGENATION";
const SET_APPLICATION_TABLE_SORTER = "SET_APPLICATION_TABLE_SORTER";
const SET_APPLICATION_TABLE_FILTER = "SET_APPLICATION_TABLE_FILTER";
const SET_APPLICATION_TABLE_FILTER_TYPE = "SET_APPLICATION_TABLE_FILTER_TYPE";
const SET_COMPONENT_DATA = "SET_COMPONENT_DATA";
const SET_SELECTED_COMPONENT_ID = "SET_SELECTED_COMPONENT_ID";
const SET_COMPONENT_TABLE_PAGENATION = "SET_COMPONENT_TABLE_PAGENATION";
const SET_COMPONENT_TABLE_SORTER = "SET_COMPONENT_TABLE_SORTER";
const SET_COMPONENT_TABLE_FILTER = "SET_COMPONENT_TABLE_FILTER";
const SET_COMPONENT_TABLE_FILTER_TYPE = "SET_COMPONENT_TABLE_FILTER_TYPE";
const SET_FAVORITE_DATA = "SET_FAVORITE_DATA";
const SET_SELECTED_FAVORITE_ID = "SET_SELECTED_FAVORITE_ID";
const SET_FAVORITE_TABLE_COLUMNS = "SET_FAVORITE_TABLE_COLUMNS";
const SET_FAVORITE_TABLE_PAGENATION = "SET_FAVORITE_TABLE_PAGENATION";
const SET_FAVORITE_TABLE_SORTER = "SET_FAVORITE_TABLE_SORTER";
const SET_FAVORITE_TABLE_FILTER = "SET_FAVORITE_TABLE_FILTER";
const SET_BULLETINBOARD_DATA = "SET_BULLETINBOARD_DATA";
const SET_BULLETINBOARD_TABLE_PAGENATION = "SET_BULLETINBOARD_TABLE_PAGENATION";
const SET_BULLETINBOARD_TABLE_SORTER = "SET_BULLETINBOARD_TABLE_SORTER";
const SET_USER_INFO_DATA = "SET_USER_INFO_DATA";
const SET_QUICK_LINK_DATA = "SET_QUICK_LINK_DATA";
const SET_PROJECT_DATA = "SET_PROJECT_DATA";
const SET_PROJECT_PAGENATION = "SET_PROJECT_PAGENATION";
const SET_SELECTED_PROJECT_ID = "SET_SELECTED_PROJECT_ID";
const SET_SHOW_HOME_CARD = "SET_SHOW_HOME_CARD";
const SET_SHOW_HOME_DATE = "SET_SHOW_HOME_DATE";
const SET_SHOW_HOME_SEARCH = "SET_SHOW_HOME_SEARCH";
const SET_SHOW_HOME_LINK = "SET_SHOW_HOME_LINK";
const SET_SHOW_HOME_MENU = "SET_SHOW_HOME_MENU";
const SET_SHOW_HOME_QUICK_LINK = "SET_SHOW_HOME_QUICK_LINK";
const SET_SHOW_HOME_FOOTER = "SET_SHOW_HOME_FOOTER";

export {
  SET_PORTFOLIO_DATA,
  SET_SELECTED_PORTFOLIO_ID,
  SET_LEETCODE_DATA,
  SET_SELECTED_LEETCODE_ID,
  SET_LEETCODE_TABLE_COLUMNS,
  SET_LEETCODE_TABLE_PAGENATION,
  SET_LEETCODE_TABLE_SORTER,
  SET_LEETCODE_TABLE_FILTER,
  SET_LEETCODE_TABLE_FILTER_TYPE,
  SET_APPLICATION_DATA,
  SET_SELECTED_APPLICATION_ID,
  SET_APPLICATION_TABLE_COLUMNS,
  SET_APPLICATION_TABLE_PAGENATION,
  SET_APPLICATION_TABLE_SORTER,
  SET_APPLICATION_TABLE_FILTER,
  SET_APPLICATION_TABLE_FILTER_TYPE,
  SET_COMPONENT_DATA,
  SET_SELECTED_COMPONENT_ID,
  SET_COMPONENT_TABLE_PAGENATION,
  SET_COMPONENT_TABLE_SORTER,
  SET_COMPONENT_TABLE_FILTER,
  SET_COMPONENT_TABLE_FILTER_TYPE,
  SET_FAVORITE_DATA,
  SET_SELECTED_FAVORITE_ID,
  SET_FAVORITE_TABLE_COLUMNS,
  SET_FAVORITE_TABLE_PAGENATION,
  SET_FAVORITE_TABLE_SORTER,
  SET_FAVORITE_TABLE_FILTER,
  SET_BULLETINBOARD_DATA,
  SET_BULLETINBOARD_TABLE_PAGENATION,
  SET_BULLETINBOARD_TABLE_SORTER,
  SET_PROJECT_DATA,
  SET_PROJECT_PAGENATION,
  SET_SELECTED_PROJECT_ID,
  SET_USER_INFO_DATA,
  SET_QUICK_LINK_DATA,
  SET_SHOW_HOME_CARD,
  SET_SHOW_HOME_DATE,
  SET_SHOW_HOME_SEARCH,
  SET_SHOW_HOME_LINK,
  SET_SHOW_HOME_MENU,
  SET_SHOW_HOME_QUICK_LINK,
  SET_SHOW_HOME_FOOTER,
};
